<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="form formMarginBtm20">
        <FormItem>
          <span class="label">订单号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250 marginRight70" v-model="queryFrom.order_number"></i-input>
        </FormItem>
        <FormItem>
          <span class="label">供应商名称：</span
          ><Select class="iviewIptWidth250 marginRight90" v-model="queryFrom.supplier_id" clearable filterable>
            <Option v-for="item in supplierNameList" :value="item.supplier_id" :key="item.supplier_id">{{ item.supplier_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label">下单时间：</span>
          <DatePicker class="iviewIptWidth250" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime($event, 1)" clearable></DatePicker>
          <span class="choseTimeZhi">至</span>
          <DatePicker v-model="queryFrom.end_insert_time" :options="options" class="iviewIptWidth250" format="yyyy-MM-dd" clearable placeholder="请选择" @on-change="changeTime($event, 2)"></DatePicker>
        </FormItem>
        <br />
        <FormItem>
          <span class="label">申请单号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250 marginRight70" v-model="queryFrom.apply_order_number"></i-input>
        </FormItem>
        <FormItem>
          <span class="label">订单类型：</span
          ><Select class="iviewIptWidth250 marginRight70" v-model="queryFrom.purchase_order_type" clearable>
            <Option v-for="item in orderTypeList" :value="item.value" :key="item.value + item.name">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label">状态：</span
          ><Select class="iviewIptWidth250" v-model="queryFrom.purchase_order_state" clearable @on-change="stateChange">
            <Option v-for="item in orderStateList" :value="item.value" :key="item.value + item.name">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="20">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="pageBtn finger btnReset marginLeft20" @click="addOrder" v-if="insertAble">新增</span>
          <span class="longTextBtn finger btnReset marginLeft20" @click="payReport">生成付款报告</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}({{ item.num }})
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table @on-sort-change="sortChange" :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" :rowClass="rowClassName" :loading="isLoad" totalText="条记录">
        <div slot="summary" class="summary" v-if="listData.length > 0">
          <div>
            <span style="margin-right: 20px;"
              >总数量：<span class="color389">{{ totalNum }}</span></span
            >
            <span>合计金额：</span>
            <span class="color389">¥{{ totalMoney }}</span>
          </div>
        </div>
      </Table>
    </div>
        <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>



    <tips-component showModal="delete" v-if="tipStatus" @cancleBtn="tipStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--     反冲-->
    <tips-component showModal="recoil" v-if="recoilStatus" @cancleBtn="recoilStatus = false" @sureBrn="sureRecoil"></tips-component>
    <!-- 部分发货 关闭 -->
    <tips-component showModal="close" v-if="closeStatus" @cancleBtn="closeStatus = false" @sureBrn="sureClose"></tips-component>
  </div>
</template>

<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
import { mapState } from 'vuex'
export default {
  name: 'purchaseOrderConsign',
  components: {
    Table,
    TipsComponent,
  },
  computed: {
    ...mapState(['supplierNameList']),
  },
  data() {
    return {
      billNumber: '',
      paging: null,
      clickId: null,
      status: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
      ],
      template_id: '',
      template_type: 1,
      typeList: [],
      whetherEmpty: true,
      recoilStatus: false,
      closeStatus: false,
      tipStatus: false,
      totalNum: 0,
      totalMoney: 0,
      isLoad: true,
      navFrist: [
        {
          name: '全部',
          num: 140,
          type: '-2',
        },
        {
          name: '草稿',
          num: 40,
          type: '-1',
        },
        {
          name: '待确认',
          num: 40,
          type: '1',
        },
        {
          name: '待收货',
          num: 40,
          type: '2',
        },
      ],
      clickIndex: '-2',
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
        },
        {
          title: '订单号',
          align: 'center',
          key: 'order_number',
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: param.row.purchase_order_state != -1 ? '#389AFC' : 'red',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    },
                  },
                },
                param.row.order_number
              ),
            ])
          },
          width: 150,
        },
        {
          title: '申请单号',
          key: 'apply_order_number',
          align: 'center',
          width: 120,
        },
         {
          title: '供应商名称',
          align: 'center',
          key: 'supplier_name',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class:'TextAlign_lf',
                },
                param.row.supplier_name
              ),
            ])
          },
        },
         {
          title: '产品',
          align: 'center',
          key: 'product_list',
          minWidth: 130,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class:'TextAlign_lf',
                },
                param.row.product_list
              ),
            ])
          },
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          width: 60,
        },
        {
          title: '订单金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', param.row.total_amount ? '¥' + param.row.total_amount : '0')])
          },
          width: 100,
        },
        {
          title: '下单时间',
          key: 'creatTime',
          sortable: 'custom',
          align: 'center',
          width: 150,
        },
        {
          title: '订单类型',
          key: 'purchase_order_type_str',
          align: 'center',
          width: 90,
        },
        {
          title: '备注',
          key: 'note',
          align: 'center',
          minWidth: 100,
          width: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.note
              ),
            ])
          },
        },
        {
          title: '状态',
          key: 'purchase_order_state_str',
          align: 'center',
          width: 120,
        },
        {
          title: '操作',
          align: 'center',
          width: 200,
          render: (h, param) => {
            let status = param.row.purchase_order_state
            if (status == '-1' || status == '6') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.editRow(param.row)
                      },
                    },
                  },
                  '编辑'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                      marginLeft: '20px',
                    },
                    class: {
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
              ])
            }
            if (status == '9') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.recoil(param.row)
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.CloseInfo = param.row
                        this.closeStatus = true
                      },
                    },
                  },
                  '关闭'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_number
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            }
            if (status == '0') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row)
                      },
                    },
                  },
                  '审核'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                      marginLeft: '20px',
                    },
                    class: {
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_number
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '1') {
              return h('div', [
                // h(
                //   'span',
                //   {
                //     style: {
                //       color: '#389AFC',

                //       cursor: 'pointer',
                //     },
                //     on: {
                //       click: () => {
                //         // this.ship(param.row)
                //         this.$Message.warning('功能建设中...')
                //       },
                //     },
                //   },
                //   '催单'
                // ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                      // marginLeft: '20px',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.recoil(param.row)
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                      marginLeft: '20px',
                    },
                    class: {
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_number
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '2' || status == '8' || status == '7') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.recoil(param.row)
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_number
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '4' || status == '5') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.upload(param.row)
                      },
                    },
                  },
                  '导出采购单'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_number
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else {
              return h('div',[
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_number
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            }
          },
        },
      ],
      listData: [],
      total: 1,
      orderTypeList: [
        {
          name: '普通采购',
          value: 1,
        },
        {
          name: '委托代销',
          value: 2,
        },
        {
          name: '自寄售',
          value: 3,
        },
        {
          name: '上游寄售',
          value: 4,
        },
      ],
      orderStateList: [
        {
          name: '草稿',
          value: '-1',
        },
        {
          name: '待审核',
          value: '0',
        },
        {
          name: '待确认',
          value: '1',
        },
        {
          name: '待发货',
          value: '8',
        },
        {
          name: '待反冲',
          value: '7',
        },
        {
          name: '待收货',
          value: '2',
        },
        {
          name: '部分收货',
          value: '9',
        },
        {
          name: '已收货待入库',
          value: '3',
        },
        {
          name: '已入库待开票',
          value: '4',
        },
        {
          name: '已完成',
          value: '5',
        },
        {
          name: '审核不通过',
          value: '6',
        },
      ],
      queryFrom: {
        page: 1,
        rows: 10,
        order_number: '',
        supplier_id: '',
        start_insert_time: '',
        end_insert_time: '',
        purchase_order_type: '',
        purchase_order_state: '',
        dimension: '',
        home_click: '',
      },
      options: {},
      delectInfo: {}, // 暂存删除数据
      CloseInfo: {}, // 暂存关闭数据
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  mounted() {
    let flag = this.$route.query.select
    if (flag) {
      this.queryFrom.home_click = '1'
    }
    this.query()
    this.getRightVisibal()
  },
  watch: {
    $route(to, from) {
      switch (from.path) {
        case '/purchaseOrderDetailsConsign':
          this.whetherEmpty = false
          console.log(from.path,'旧地址',this.whetherEmpty)
          break
        case '/editPurchaseOrderConsign':
          this.whetherEmpty = false
          break
        default:
          this.whetherEmpty = true
          break
      }
    },
  },
  activated() {
    if(sessionStorage.getItem('updataCache') == 0) {
      this.query()
      sessionStorage.setItem('updataCache','1')
    }
    // if (this.whetherEmpty) {
    //   console.log('经来说了',this.whetherEmpty)
    //   this.queryFrom = {
    //     page: 1,
    //     rows: 10,
    //     order_number: '',
    //     supplier_id: '',
    //     start_insert_time: '',
    //     end_insert_time: '',
    //     purchase_order_type: '',
    //     purchase_order_state: '',
    //     dimension: '',
    //     home_click: '',
    //   }
    // }
    // this.query()
  },
  methods: {
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.purchase_order_state== -1) return 'redRow'
      return ''
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type}, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate,{template_type: this.template_type},true).then((res) => {
        if(res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
        // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 打印模板
    surePrint() {
      let api_info = `${this.$apiConsign.pruchaseOrderDetail.url}?id=${this.clickId}`
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$apiConsign.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = `${this.billNumber}.xlsx`
        aLink.click()
      })
    },
    // 列表单号、时间排序
    sortChange(data) {
      console.log(data)
      if (data.key === 'creatTime') {
        this.queryFrom.sort_str = 'insert_time'
      } else {
        this.queryFrom.sort_str = data.key
      }
      this.queryFrom.sort_type = data.order
      this.queryList()
    },
    // 部分发货 关闭按钮
    sureClose() {
      this.$http.put(this.$apiConsign.pruchaseOrderClose, { id: this.CloseInfo.id }, true).then(res => {
        this.closeStatus = false
        this.$Message.success('关闭成功')
        this.query()
      })
    },
    sureRecoil() {
      this.$http.get(this.$apiConsign.pruchaseOrderRecoil, { id: this.delectInfo.id }, true).then(res => {
        this.recoilStatus = false
        this.$Message.success('反冲成功')
        this.query()
      })
    },
    // 点击反冲按钮
    recoil(row) {
      this.delectInfo = row
      this.recoilStatus = true
    },
    // 编辑
    editRow(row) {
      this.$router.push({
        path: '/editPurchaseOrderConsign',
        query: {
          id: row.id,
        },
      })
    },
    // 确认删除
    sureDelete() {
      this.$http.DeleteAll(this.$apiConsign.pruchaseOrderManage, { id: this.delectInfo.id }, true).then(res => {
        this.query()
        this.tipStatus = false
      })
    },
    // 点击删除按钮
    deleteRow(row) {
      this.delectInfo = row
      this.tipStatus = true
    },
    // 状态改变
    stateChange(e) {
      if (e == '-1' || e == '1' || e == '2') {
        this.clickIndex = e
      } else {
        this.clickIndex = '-2'
      }
      this.query()
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.start_insert_time = e
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.start_insert_time) - 86400000
          },
        }
      } else {
        this.queryFrom.end_insert_time = e
      }
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.isLoad = true
      this.$http.get(this.$apiConsign.pruchaseOrderManage, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.totalMoney = res.total_amount
        this.totalNum = res.total_number
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].creatTime = res.data[i].insert_time ? this.$moment(res.data[i].insert_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
        for (let i = 0; i < res.tab_count.length; i++) {
          this.navFrist[i].num = res.tab_count[i]
        }
      })
    },
    ship() {
      this.$router.push({ path: '/orderShip' })
    },
    goDetails(row) {
      this.$router.push({
        path: '/purchaseOrderDetailsConsign',
        query: {
          id: row.id,
        },
      })
    },
    // 生成付款报告
    payReport () {
      if (!this.queryFrom.supplier_id) {
        this.$Message.warning('请先选择供应商')
        return
      }
      let cloneObj = JSON.parse(JSON.stringify(this.queryFrom))
      delete  cloneObj.page
      delete  cloneObj.rows
      let obj = {
         ...cloneObj
        }
      this.$http.downFile(this.$apiConsign.purchaseOrderPayReport, obj, true).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '付款报告.xlsx'
        aLink.click()
      })
    },
    addOrder() {
      this.$router.push({ path: '/addPurchaseOrderConsign' })
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    clickNav(type) {
      this.clickIndex = type
      if (type != '-2') {
        this.queryFrom.purchase_order_state = type
      } else {
        this.queryFrom.purchase_order_state = ''
      }
      this.queryFrom.page = 1
      this.queryList()
    },
    upload(row) {
      this.$http.downFile(this.$apiConsign.pruchaseOrderExport, { id: row.id }, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '采购订单' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
}
</script>

<style scoped lang="less">
.item{
  margin-bottom: 20px;
}
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
      position: relative;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
